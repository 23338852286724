.large-header-text {
  position: relative;
  width: 100%;
  height: 90px;
  line-height: 90px;
  will-change: transform, opacity;
  overflow: hidden;
}

.large-header-text > div {
  overflow: hidden;
}

.large-header {
  font-weight: 800;
  font-size: 68px;
  margin: 0;
  margin-bottom: 15px;
  max-width: 50vw;
  line-height: 70px;
}

@media only screen and (max-width: 1265px) {
  .large-header {
    font-size: 50px;
    max-width: 100vw;
    line-height: 50px;
  }
}

.large-header-inner-mobile {
  display: none;
}

@media only screen and (max-width: 800px) {
  .large-header-inner-desktop {
    display: none;
  }

  .large-header-inner-mobile {
    display: grid;
  }
  .large-header {
    font-size: 30px;
    max-width: 100vw;
  }
}
