body {
  font-family: "DM Sans" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  /* margin: 0 2vw !important; */
  margin: 0 !important;
}

html {
  overflow-x: hidden;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: #e4e4e4;
}

.full-screen-container {
  min-width: 100vw;
  min-height: 100vh;
}

.full-screen-width {
  width: 100vw;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.medium-header {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 1.2em;
  color: #000000;
  margin: 0;
  /* max-width: 300px; */
}

.square-paragraph {
  color: #2e2e2e;
  opacity: 0.7;
  font-weight: 500;
  font-size: 18px;
  margin-top: 40px;
  line-height: 237.5%;
  /* text-align: justify; */
}

.center-header {
  text-align: center;
}

.white-text {
  color: #fefefe !important;
}

.black-bg {
  background: black;
}

.white-txt {
  background: black;
}

.max-height {
  min-height: 100vh;
}

.light-light-grey-bg {
  background: #f5f5f5;
}
