.single-tech-list {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  text-align: center;
  color: #9c9c9c;
}

.technology-bg {
  background: #ffffff;
}
