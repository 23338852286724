.contact-description {
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0;
  color: white;
  mix-blend-mode: difference;
  text-align: start;
}

.contact-title {
  color: white;
  mix-blend-mode: difference;
  text-align: start;
}

.contact-hello-btn {
  color: white;
}

.contact-link {
  mix-blend-mode: difference;
  color: white;
  cursor: pointer;
  opacity: 0.4;
  margin: 5px 0;
  text-decoration: none;
}

.contact {
  background: black;
  height: 100vh;
  z-index: 1;
}

.contact-inner-container {
  position: relative;
  height: 100%;
}
