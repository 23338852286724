.full-mobile-card-body {
  width: 100%;
}

.full-mobile-card-text p {
  max-width: 500px;
}

.full-mobile-card-text {
  width: 100%;
  margin: 100px 0;
}

.full-mobile-card-container {
  /* border-radius: 10px; */
  opacity: 1;
}

.full-mobile-card-grid-item {
  width: 100%;
  padding-top: 5vh !important;
}

.full-mobile-card-animated-div {
  position: relative;
}

.full-mobile-card-number {
  margin: 0;
  margin-left: 25px;
  font-size: 24px;
  color: rgb(54, 54, 54);
}

.full-mobile-card-hr {
  border-top: 1px solid rgb(161, 161, 161);
  width: 40%;
}
