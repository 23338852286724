.landing-bg {
  background-color: #ffffff;
  display: grid;
  grid-template-rows: 2fr 4fr 2fr;
  min-height: 100vh;
}

.landing-p {
  color: #535353;
  font-weight: 500;
  font-size: 18px;
  margin-top: 40px;
  line-height: 40px;
}

.landing-container {
  justify-content: flex-start;
}

.landing-svg-social {
  width: 35px;
  height: 35px;
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(326deg)
    brightness(103%) contrast(102%);
}

.landing-hr {
  width: 15vw;
  background-color: rgb(0, 0, 0);
  height: 10px;
  float: left;
}

.landing-text-container {
  padding-right: 50px;
}

@media only screen and (max-width: 1265px) {
  .landing-text-container {
    padding-right: 0px;
  }
}

.landing-keyboard {
  width: 100%;
  max-width: 700px;
}
