.large-proj-card-img {
  max-height: 45vh;
  z-index: 1;
  max-width: 100%;
  min-height: 300px;
}

.large-proj-card-number {
  margin: 0;
  margin-left: 25px;
  font-size: 24px;
  color: rgb(54, 54, 54);
}

.large-proj-card-hr {
  border-top: 1px solid rgb(161, 161, 161);
}

.large-proj-tags {
  margin: 0;
  color: rgb(82, 82, 82);
  display: inline;
  margin-right: 18px;
}

.large-proj-img-container {
  position: relative;
}

.large-proj-card-text {
  min-width: 300px;
}

.large-proj-card-page {
  margin: 30px 10px 0 30px;
  cursor: pointer;
  color: rgb(102, 102, 102);
}

.large-proj-card-page-active {
  font-weight: 600;
  color: rgb(51, 51, 51);
}

.large-proj-card-img-container {
  width: 100%;
}
