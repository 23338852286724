.mobile-apps-phone-1 {
  border-radius: 40px;
  border: 15px solid black;
  width: 25vw;
  max-width: 300px;
  min-width: 300px;
}

.mobile-apps-phone-2 {
  border-radius: 40px;
  border: 15px solid rgb(61, 61, 61);
  max-width: max(25vw, 300px);
  width: 25vw;
  max-width: 300px;
  min-width: 300px;
}

.mobile-apps-r1-c1 {
  height: 100%;
  width: 100%;
}

.mobile-apps-r1-c2 {
  height: 100%;
  width: 100%;
}

.mobile-apps-r2-c1 {
  height: 100%;
  width: 100%;
}

.mobile-apps-r2-c2 {
  height: 100%;
  width: 100%;
}

.mobile-apps-storyboard {
}

.mobile-apps-storyboard-container img {
  width: 80%;
  border-radius: 30px;
}

.mobile-apps-storyboard-img1 {
  border: 10px solid rgb(24, 24, 24);
  max-width: 275px;
}

.mobile-apps-storyboard-img2 {
  border: 10px solid rgb(209, 209, 209);
  max-width: 275px;
}

.mobile-apps-storyboard-img3 {
  border: 10px solid rgb(24, 24, 24);
  max-width: 275px;
}

@media only screen and (min-width: 1024px) {
  .mobile-apps-storyboard-img1 {
    margin-top: 0vh;
  }

  .mobile-apps-storyboard-img2 {
    margin-top: 20vh;
  }

  .mobile-apps-storyboard-img3 {
    margin-top: 40vh;
  }
}
