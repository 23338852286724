.projects-container {
  width: 100vw !important;
  /* padding: 2vh 2vw; */
  padding-bottom: 7px;
}

.project-header {
  margin-top: 0;
  color: #242425;
  font-weight: 500;
  font-size: 32px;
}
.project-body {
  font-size: 15px;
  line-height: 27px;
  max-width: 400px;
  color: #b9b9b9;
  margin-top: 0;
}

.project-rounded {
  /* border-radius: 10px; */
}

.project-container video:focus {
  outline: none;
}

.project {
  padding: 10vh 0 !important;
}

.project-top-text {
  text-align: center;
  max-width: 100%;
}

.project-top-text {
  text-align: center;
}
