.non-mobile-projects-crystal-img {
  width: 100%;
  object-fit: contain;
}

.non-mobile-projects-crystal {
  color: white;
  background-color: rgb(3, 3, 3);
}

.non-mobile-projects-video {
  width: 100%;
}
