.education {
  width: 100%;
}

.education-card {
  padding-bottom: 12vh;
}

.education-subheader {
  text-align: center;
}

.education-text-inner {
  color: #686868;
  width: 100%;
  height: 100%;
  line-height: 40px !important;
}

.education-card-container {
  height: 100%;
}

.education-custom-container {
  padding-top: 0;
}
