.navbar {
  /* padding: 100px 0px; */
  width: 100%;
  background-color: rgb(255, 255, 255);
  align-items: center;
  display: flex;
}
.nav-link-container-desktop p {
  margin-left: 30px;
  font-size: 14px;
  cursor: pointer;
}

.nav-link-container-desktop {
  margin-left: auto;
  padding-right: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  font-weight: 500;
}

.nav-link-container-mobile > p {
  margin-left: 50px;
  font-size: 18px;
}

.nav-link-container-mobile {
  margin-left: auto;
  padding-right: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  display: none;
  font-family: "Poppins";
}

@media only screen and (max-width: 1024px) {
  .nav-link-container-desktop {
    display: none;
  }
  .nav-link-container-mobile {
    display: flex;
  }
}

.navbar-menu p {
  font-family: "DM Sans" !important;
  padding-right: 50px;
}

.nav-logo {
  padding-top: 0px;
}

.nav-container {
  padding: 0 4vw !important;
}
