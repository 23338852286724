.single-exp-container {
  padding: 100px 0;
}

.single-exp-title {
  font-size: 36px;
  font-weight: 500;
  margin: 0;
  text-align: start;
  display: block;
  color: #d5d5da;
  z-index: 2;
}

.single-exp-caption {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  text-align: start;
  line-height: 30px;
  color: #b4b4b7;
}

.single-exp-loc {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  text-align: start;
  line-height: 30px;
  color: #575757;
  z-index: 2;
}

.single-exp-caption-div {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  text-align: start;
  line-height: 30px;
  color: #909090;
  z-index: 2;
}

.single-exp-caption-line {
  border: 1px solid #252525;
  width: 50%;
  margin: 0 auto;
}

.single-exp-paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 33px;
  text-align: justify;
  color: #adadad;
  width: 100%;
}

.single-exp-bullets {
  font-weight: 400;
  font-size: 14px !important;
  width: 80%;
  line-height: 1.5;
  line-height: 30px;
  color: #5d5d5d;
}

.single-exp-card {
  padding: 50px;
  border-radius: 19px;
  margin: -29px !important;
}

.single-exp-index {
  font-size: 200px;
  font-weight: 700;
  margin: 0;
  text-align: end;
  position: absolute;
  opacity: 0.3;
  z-index: 0;
  padding-left: 24px;
  overflow: hidden;
}

.exp-container {
  margin: 0px 0;
  position: relative;
}

.single-exp-text-container {
  padding-top: 40px !important;
}

.single-exp-body {
  height: 100%;
  padding: 50px 0;
  width: 100%;
}

.single-exp-hex {
  width: 25px;
  height: 25px;
  border: 2px solid white;
  border-radius: 5px;
  display: inline;
  margin-right: 24px;
}

.single-exp-caption-binary {
  width: 70%;
  color: rgb(233, 233, 233);
  margin: 0;
  display: inline;
  text-align: end;
  width: 100%;
  font-size: 24px;
}

.exp {
  background: #181819;
  padding: 10vh 0;
}
