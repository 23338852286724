.two-half-text-container {
  margin: 80px 0;
}

.two-half-grid-item {
  padding: 0;
  height: 100%;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
}

.two-half-grid-item video {
  outline: none;
}
