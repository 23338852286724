.about-avatar-container {
  position: relative;
  height: 100%;
  width: 100%;
}
.about-avatar-portrait {
  width: 100%;
  margin: 80px 0;
  height: 450px;
  object-fit: cover;
}

.about-avatar-portrait:hover {
  filter: grayscale(0%);
}

.about-avatar-svg {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  text-align: center;
}
.about-avatar-svg2 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 13px;
  top: 15px;
  text-align: center;
}

.about-paragraph {
  margin-top: 0;
}

.about-parallax {
  /* The image used */
  background-image: url("../../assets/setup.jpg");
  /* Set a specific height */
  height: 45vh;
  max-height: 600px;
  margin-top: 70px;
  width: 100%;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
